<template>
    <div
        ref="jumpLinksHorizontal"
        class="jump-links-horizontal-container"
    >
        <nav class="jump-links-horizontal">
            <div class="jump-links-horizontal__inner">
                <span
                    class="jump-links-horizontal__list-bar"
                    :style="{ width: `${activeItemWidth}px`, left: `${activeItemPosition}px` }"
                ></span>

                <ul class="jump-links-horizontal__list">
                    <li
                        v-for="item in items"
                        :key="item.label"
                        class="jump-links-horizontal__list-item"
                    >
                        <a
                            :href="`#${item.id}`"
                            class="jump-links-horizontal__list-item-link"
                            :class="{ 'jump-links-horizontal__list-item--active u-paragraph u-bold': activeItemId === item.id }"
                            @click.prevent="triggerScroll(`#${item.id}`, -70)"
                        >
                            {{ item.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue';
import { scrollToSelector } from '../../../js/utils/scroll-to-selector';

const props = defineProps({
    items: {
        type: [Object, Array],
        required: true,
    },
});

const activeItem = ref(null);
const activeItemWidth = ref(0);
const activeItemPosition = ref(0);
const activeItemId = ref(props.items[0].id || '');
const jumpLinksHorizontal = ref(null);

const triggerScroll = (selector, offset) => {
    scrollToSelector(selector, offset);
};

const handleActiveItem = () => {
    setTimeout(() => {
        activeItem.value = jumpLinksHorizontal.value.querySelector('.jump-links-horizontal__list-item--active');

        if (activeItem.value) {
            activeItemWidth.value = activeItem.value.offsetWidth;
            activeItemPosition.value = activeItem.value.offsetLeft;

            activeItem.value.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'center',
            });
        }
    }, 0);
};

let linkObserver = null;

const observeLinks = () => {
    const options = {
        threshold: 0,
        rootMargin: '0px 0px -70% 0px',
    };

    linkObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                activeItemId.value = entry.target.id;
                handleActiveItem();
            }
        });
    }, options);

    props.items.forEach(item => {
        const element = document.getElementById(item.id);
        if (element) {
            linkObserver.observe(element);
        }
    });
};

onMounted(() => {
    nextTick(() => {
        observeLinks();
        handleActiveItem();
    });
});

onUnmounted(() => {
    if (linkObserver) {
        linkObserver.disconnect();
    }
});
</script>

<style lang="scss">
@use 'watson-organism-jump-links-horizontal';
</style>
